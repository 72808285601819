import { Button, ButtonGroup, Icon } from "@blueprintjs/core";
import React, {
  FunctionComponent,
  MutableRefObject,
  PropsWithChildren,
  ReactElement,
  useState,
} from "react";
import { useHistory } from "react-router-dom";
import {
  RegistrationComponentProps,
  RegistrationComponentRenderer,
} from "../lib";

const styles = {
  headerContainer: {
    fontSize: "1.7rem",
    fontWeight: "bold" as const,
    color: "var(--theme-purple)",
    display: "flex",
    flexDirection: "row" as const,
    justifyContent: "space-between",
  },
  title: {
    padding: "5% 0%",
  },
  footerMessage: {
    color: "var(--theme-medium-gray)",
    padding: "5% 0%",
    fontSize: "0.8rem",
  },
  buttonGroup: {
    display: "flex",
    flexDirection: "row" as const,
    justifyContent: "center",
    margin: "4% 0%",
  },
  button: {
    width: "30%",
    margin: "0% 1%",
  },
};

export const RegistrationSteps: FunctionComponent<
  PropsWithChildren<{
    stepIndex: number;
    baseRoute: string;
    loginRoute: string;
    maxStep: number;
    onNextClickRef: MutableRefObject<() => Promise<any>>;
    renderComponent: RegistrationComponentRenderer;
    registrationCompleteRoute: string;
    title?: ReactElement | string;
    footerMessage?: ReactElement | string;
  }>
> = ({
  renderComponent,
  registrationCompleteRoute,
  onNextClickRef,
  loginRoute,
  baseRoute,
  stepIndex,
  maxStep,
  title,
  footerMessage,
  children,
}) => {
  const hist = useHistory();
  const [prevIsDisabled, setPrevIsDisabled] = useState<boolean>(false);
  const isFirstStep = stepIndex === 1;
  const [nextIsDisabled, setNextIsDisabled] = useState<boolean>(true);
  const isLastStep = stepIndex >= maxStep;

  const onNextClick = async () => {
    async () => {
      return await onNextClickRef.current();
    };
    hist.push(`${baseRoute}/${stepIndex + 1}`);
  };
  const onSubmitClick = async () => {
    await onNextClickRef.current();
    return hist.push(registrationCompleteRoute);
  };

  return (
    <div style={{ height: "100vh", padding: "8%" }}>
      {!isFirstStep && (
        <div style={styles.headerContainer}>
          <Icon
            style={{ margin: "auto 0" }}
            icon={"arrow-left"}
            onClick={() => hist.push(`${baseRoute}/${stepIndex - 1}`)}
          />
          <div style={{ width: "65%", textAlign: "left" }}>Daftar</div>
        </div>
      )}
      {title && <div style={styles.title}>{title}</div>}
      {children}
      {renderComponent({
        setPrevIsDisabled,
        setNextIsDisabled,
      } as RegistrationComponentProps)}
      {footerMessage && <div style={styles.footerMessage}>{footerMessage}</div>}
      <ButtonGroup style={styles.buttonGroup}>
        {isFirstStep && (
          <Button
            style={styles.button}
            onClick={() => hist.push(loginRoute)}
            disabled={prevIsDisabled}
            text="Cancel"
          />
        )}
        <Button
          style={styles.button}
          disabled={nextIsDisabled}
          onClick={isLastStep ? onSubmitClick : onNextClick}
          text={isLastStep ? "Ok" : "Berikutnya"}
        />
      </ButtonGroup>
    </div>
  );
};
