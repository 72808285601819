import { Button, Card, Elevation, Icon, Spinner } from "@blueprintjs/core";
import { DateInput } from "@blueprintjs/datetime";
import axios from "axios";
import React, { useEffect, useState } from "react";
import { Link, useHistory } from "react-router-dom";
import {
  apiBaseUrl,
  DailyRecordStatus,
  DailyRecordType,
} from "../../../lib/sharedBackendModels";
import { dateFormatter } from "../../../utils/formatters";
import { useToaster } from "../../../utils/toaster";
import { PageComponent } from "../lib";
import { TeamDataCard } from "./components/TeamDataCard";
import fs from "fs";
import AWS from "aws-sdk";
const styles = {
  rowFlex: {
    display: "flex",
    flexDirection: "row" as const,
    marginBottom: "2%",
  },
  selectKey: {
    marginRight: "5%",
    minWidth: "20%",
  },
  selectField: {
    borderRadius: "5px",
    background: "none",
    width: "80%",
    marginLeft: "auto",
  },
  button: {
    width: "100%",
    color: "var(--theme-purple)",
    margin: "0% 1%",
    fontFamily: "Poppins",
  },
  body: { display: "flex", flexDirection: "column" as const, padding: "5% 0%" },
};

export const History: PageComponent = ({
  onTopIconClickRef,
  accessToken,
  role,
}) => {
  const [statusToShow, setStatusToShow] = useState<string | undefined>();
  const [allDailyRecordHistoryData, setAllDailyRecordHistoryData] = useState<
    DailyRecordType[]
  >([]);
  const toaster = useToaster();
  const [isLoading, setIsLoading] = useState<boolean>(false);
  const [filteredCardDate, setFilteredCardDate] = useState<Date>();
  const [showFilter, setShowFilter] = useState<boolean>(true);

  const hist = useHistory();
  onTopIconClickRef.current = () => {
    setIsLoading(true);
    axios
      .get(`${apiBaseUrl}/daily-record`, {
        headers: {
          "x-access-token": accessToken,
          "Access-Control-Allow-Origin": "*",
          credentials: "include",
        },
      })
      .then((res) => {
        if (res.status === 200 || res.status === 304) {
          setAllDailyRecordHistoryData(res.data.dailyRecords);
        }
      })
      .catch((e) => {
        toaster?.current?.show({
          message: `${e}`,
          intent: e.toString().includes("status code 500")
            ? "danger"
            : "warning",
        });
      })
      .finally(() => {
        setIsLoading(false);
      });
  };

  useEffect(() => {
    setIsLoading(true);
    axios
      .get(`${apiBaseUrl}/daily-record`, {
        headers: {
          "x-access-token": accessToken,
          "Access-Control-Allow-Origin": "*",
          credentials: "include",
        },
      })
      .then((res) => {
        if (res.status === 200 || res.status === 304) {
          setAllDailyRecordHistoryData(res.data.dailyRecords);
        }
      })
      .catch((e) => {
        toaster?.current?.show({
          message: `${e}`,
          intent: e.toString().includes("status code 500")
            ? "danger"
            : "warning",
        });
      })
      .finally(() => {
        setIsLoading(false);
      });
  }, []);

  const filteredAllDailyRecordHistoryData = allDailyRecordHistoryData
    .filter((d: DailyRecordType) => {
      return (
        d.status === DailyRecordStatus.Reviewed ||
        (d.status === DailyRecordStatus.Rejected && d.feedbackNum! > 3)
      );
    })
    .filter((d: DailyRecordType) => {
      return (
        (statusToShow ? d.status === statusToShow : true) &&
        (filteredCardDate
          ? dateFormatter(d.RecordShift.Shift.startDate) ===
            dateFormatter(filteredCardDate)
          : true)
      );
    });

  const onSubmitClick = async () => {
    await axios
      .post(
        `${apiBaseUrl}/pdf-gen`,
        {
          shift: dateFormatter(filteredCardDate)
            ? dateFormatter(filteredCardDate)
            : dateFormatter(new Date()),
          status: statusToShow,
        },
        {
          headers: {
            "x-access-token": accessToken,
            "Access-Control-Allow-Origin": "*",
            credentials: "include",
          },
        },
      )
      .then(async (res) => {
        const response = JSON.parse(JSON.stringify(res));
        if (response.status === 200) {
          toaster?.current?.show({
            message: "PDF Generated!",
            intent: "success",
          });
          const link = document.createElement("a");
          link.href = res.data.params.Location;
          link.download = `Report - ${
            dateFormatter(filteredCardDate)
              ? dateFormatter(filteredCardDate)
              : dateFormatter(new Date())
          } - ${statusToShow}.pdf`;
          link.click();
          link.parentNode?.removeChild(link);
        }
      })
      .catch((e) => {
        const err = JSON.parse(JSON.stringify(e));
        toaster?.current?.show({
          message: `Some error occured!` + err,
          intent: "warning",
        });
      });
  };

  return (
    <>
      {isLoading && (
        <div style={{ position: "fixed", left: "45%" }}>
          <Spinner />
        </div>
      )}
      <Card style={{ marginBottom: "3%" }}>
        <Icon
          icon={"filter"}
          onClick={() => {
            setShowFilter(!showFilter);
          }}
        />
        {showFilter && (
          <>
            <div style={styles.rowFlex}>
              <div style={styles.selectKey}>status:</div>
              <select
                style={{
                  ...styles.selectField,
                  border: "solid 1px var(--theme-medium-gray)",
                }}
                onChange={(e) => {
                  setStatusToShow(e.target.value);
                }}
              >
                <option value={""}></option>
                <option value={DailyRecordStatus.Reviewed}>
                  {DailyRecordStatus.Reviewed}
                </option>
                <option value={DailyRecordStatus.Rejected}>
                  {DailyRecordStatus.Rejected}
                </option>
              </select>
            </div>
            <div style={styles.rowFlex}>
              <div style={styles.selectKey}>tanggal:</div>
              <div style={styles.selectField}>
                <DateInput
                  fill
                  placeholder="Pilih tanggal"
                  formatDate={(date) => date.toLocaleDateString()}
                  parseDate={(str) => new Date(str)}
                  maxDate={new Date()}
                  onChange={(selectedDate) => setFilteredCardDate(selectedDate)}
                />
              </div>
            </div>
          </>
        )}
      </Card>
      {filteredAllDailyRecordHistoryData.length > 0 ? (
        <>
          <TeamDataCard
            allDailyRecordsData={filteredAllDailyRecordHistoryData.sort(
              (a, b) => (a.updatedAt! > b.updatedAt! ? -1 : 1),
            )}
            isHomePage={false}
            role={role}
          />
          <div style={styles.body}>
            <Button
              text="Export to PDF"
              onClick={onSubmitClick}
              style={{
                ...styles.button,
                color: "var(--theme-purple)",
              }}
            />
          </div>
        </>
      ) : (
        !isLoading && (
          <div style={{ textAlign: "center" }}>Tidak ada record</div>
        )
      )}
    </>
  );
};
