import React, { FunctionComponent, PropsWithChildren } from "react";
import { ToasterProvider } from "./toaster";
import { BrowserRouter } from "react-router-dom";

export const AllProviders: FunctionComponent<PropsWithChildren<any>> = ({
  children,
}) => {
  return (
    <ToasterProvider>
      <BrowserRouter>{children}</BrowserRouter>
    </ToasterProvider>
  );
};
