import { Button } from "@blueprintjs/core";
import React, { FunctionComponent } from "react";
import { useHistory } from "react-router-dom";
import { DialogCard } from "../components/DialogCard";

const styles = {
  text: {
    margin: "10% auto",
  },
  button: {
    background: "var(--theme-purple)",
    color: "white",
  },
};
export const CheckerSubmittedStatusDialog: FunctionComponent<{
  isOpen: boolean;
  onClose: () => void;
  setIsCheckerSubmittedStatusDialogOpen: React.Dispatch<
    React.SetStateAction<boolean>
  >;
  revisionReasonEntry: boolean;
}> = ({
  isOpen,
  onClose,
  setIsCheckerSubmittedStatusDialogOpen,
  revisionReasonEntry,
}) => {
  const hist = useHistory();

  return (
    <DialogCard
      title={"berhasil"}
      isOpen={isOpen}
      onClose={onClose}
      icon={"tick-circle"}
    >
      <div style={styles.text}>
        {revisionReasonEntry
          ? "Data sudah dikonfirmasi"
          : "Data telah berhasil di submit ke manager"}
      </div>
      <Button
        style={styles.button}
        onClick={() => {
          setIsCheckerSubmittedStatusDialogOpen(false);
          hist.push("/dashboard/0");
        }}
      >
        ok
      </Button>
    </DialogCard>
  );
};
