export const apiBaseUrl = "https://kpi-module-api.herokuapp.com"; // process.env.BACKEND_URL;

export enum UserRoles {
  SuperAdmin = "SuperAdmin",
  Checker = "Checker",
  Operator = "Operator",
  Manager = "Manager",
}

export enum DailyRecordStatus {
  Started = "Dimulai",
  Confirmed = "Konfirmasi",
  Pending = "Pending",
  Rejected = "Ditolak",
  Reviewed = "Diverifikasi",
}
export type RecordResultsType = {
  id: string;
  userID: string;
  dailyTarget: number;
  cratReached: number;
  createdAt?: Date;
  updatedAt?: Date;
  dRecordID: string;
};

export type UserType = {
  id: string;
  name: string;
  phone: string;
  password: string;
  crat: number;
  role: UserRoles;
  nik: string;
  address: string;
  teamMembershipID: string;
  createdAt?: Date;
  updatedAt?: Date;
  TeamMembership: TeamMembershipType;
  RecordResults: RecordResultsType[];
};

export type TeamMembershipType = {
  id: string;
  userID: string;
  teamID: string;
  createdAt?: Date;
  updatedAt?: Date;
  User: UserType;
};

export type TeamType = {
  id: string;
  userID: string;
  name: string;
  createdAt?: Date;
  updatedAt?: Date;
  TeamMemberships: TeamMembershipType[];
};

export type FeedbackType = {
  userID: string;
  dailyRecordID: string;
  comment: string;
  status: DailyRecordStatus;
  createdAt?: Date;
  updatedAt?: Date;
};

export type UnitType = {
  id: string;
  userID: string;
  name: string;
  value: number;
  createdAt?: Date;
  updatedAt?: Date;
};

export type ShiftType = {
  id: string;
  userID: string;
  startDate: Date;
  endDate: Date;
  createdAt?: Date;
  updatedAt?: Date;
};

export type RecordShiftType = {
  id: string;
  dailyRecordID: string;
  userID: string;
  shiftID: string;
  recordResultID: string;
  createdAt?: Date;
  updatedAt?: Date;
  Shift: ShiftType;
  DailyRecord: DailyRecordType;
};

export type TeamRecordType = {
  id: string;
  dailyRecordID: string;
  teamID: string;
  createdAt?: Date;
  updatedAt?: Date;
  Team: TeamType;
  DailyRecord: DailyRecordType;
};

export type DailyRecordType = {
  id?: string;
  userID: string;
  feedbackNum?: number;
  status: DailyRecordStatus;
  productionCrat: number;
  createdAt?: Date;
  updatedAt?: Date;
  RecordShift: RecordShiftType;
  Unit: UnitType;
  Feedbacks: FeedbackType[];
  TeamRecord: TeamRecordType;
};

export type UserProductionType = {
  userID: string;
  productionCrat: number;
  dailyTarget?: number;
};

export type DailyRecordRequestBodyType = {
  productionCrat: number;
  teamID: string;
  shiftID: string;
  unitID: string;
  status: DailyRecordStatus.Started;
  userProduction: UserProductionType[];
};
