import { Button } from "@blueprintjs/core";
import React, { FunctionComponent } from "react";
import { Cookies, useCookies } from "react-cookie";
import { PageComponent } from "../lib";

export const Profile: PageComponent = ({ accessToken }) => {
  return (
    <div style={{ textAlign: "center" }}>
      <Button
        style={{ fontFamily: "Poppins" }}
        text="Log Out"
        onClick={() => {
          const cookies = new Cookies();
          cookies.set("token", "", {
            domain: window.location.hostname,
            path: "/",
            secure: false, // <-- false here when served over HTTP
          });
          cookies.set("role", "", {
            domain: window.location.hostname,
            path: "/",
            secure: false, // <-- false here when served over HTTP
          });
          window.location.reload();
        }}
      />
    </div>
  );
};
