import React from "react";
import ReactDOM from "react-dom";
import App from "./pwaPages/App";
import reportWebVitals from "./reportWebVitals";
import { AllProviders } from "./utils/providers";

const httpsURL = "https://" + process.env.PREFIX_URL;
if (window.location.href.startsWith(httpsURL)) {
  const url = new URL(window.location.href);
  url.hostname = String(process.env.PREFIX_URL);
  window.location.href = url.href;
}

ReactDOM.render(
  <React.StrictMode>
    <AllProviders>
      <App />
    </AllProviders>
  </React.StrictMode>,
  document.getElementById("root"),
);

// If you want to start measuring performance in your app, pass a function
// to log results (for example: reportWebVitals(console.log))
// or send to an analytics endpoint. Learn more: https://bit.ly/CRA-vitals
reportWebVitals();
