import { Dialog, Icon, IconName } from "@blueprintjs/core";
import React, {
  FunctionComponent,
  PropsWithChildren,
  ReactElement,
} from "react";

const styles = {
  dialog: {
    textAlign: "center" as const,
    margin: "5%",
    padding: "10%",
    justifyContent: "space-evenly",
  },
  icon: {
    margin: "-25% auto 5% auto",
    color: "var(--theme-purple)",
  },
  title: {
    fontSize: "var(--theme-title-font-size)",
    textTransform: "capitalize" as const,
    paddingBottom: "5%",
  },
};

export const DialogCard: FunctionComponent<
  PropsWithChildren<{
    title: ReactElement | string;
    icon?: IconName;
    isOpen: boolean;
    onClose: () => void;
  }>
> = ({ title, children, icon, isOpen, onClose }) => (
  <Dialog style={styles.dialog} isOpen={isOpen} onClose={onClose}>
    {icon && <Icon icon={icon} iconSize={100} style={styles.icon} />}
    <div style={styles.title}>{title}</div>
    {children}
  </Dialog>
);
