import React, { FunctionComponent, useRef, useState } from "react";
import { Switch, Route } from "react-router-dom";
import {
  RegistrationComponentProps,
  RegistrationComponentRenderer,
} from "../lib";
import { AgreeTnc } from "../RegistrationSteps/AgreeTnc";
import { RegisterBank } from "../RegistrationSteps/RegisterBank";
import { RegisterHandphone } from "../RegistrationSteps/RegisterHandphone";
import { SetPasswordAndCreateUser } from "../RegistrationSteps/SetPassword";
import { UploadKTP } from "../RegistrationSteps/UploadKTP";
import { VerifyOTP } from "../RegistrationSteps/VerifyOTP";
import { RegistrationSteps } from "../RegistrationSteps";

export const RegistrationPage: FunctionComponent<{
  baseRoute: string;
  loginRoute: string;
  registrationCompleteRoute: string;
}> = ({ baseRoute, loginRoute, registrationCompleteRoute }) => {
  const [registrationToken, setRegistrationToken] = useState<string>("");
  const [handphone, setHandphone] = useState<string>("");
  const [countryCodeSelection, setCountryCodeSelection] =
    useState<string>("+62");
  const [invitationName, setInvitationName] = useState<string | undefined>();
  // eslint-disable-next-line @typescript-eslint/no-empty-function
  const onNextClickRef = useRef<() => Promise<any>>(async () => {});
  const [title, setTitle] = useState<string>();
  const [footerMessage, setFooterMessage] = useState<string>();

  const registrationBackendProps = {
    registrationToken,
    setRegistrationToken,
    onNextClickRef,
  } as RegistrationComponentProps;

  const pages = [
    // (props) => (
    //   <AgreeTnc
    //     {...props}
    //     {...registrationBackendProps}
    //     setTitle={setTitle}
    //     setFooterMessage={setFooterMessage}
    //   />
    // ),
    (props) => (
      <RegisterHandphone
        {...props}
        {...registrationBackendProps}
        phoneNumber={handphone}
        countryCodeSelection={countryCodeSelection}
        setPhoneNumber={setHandphone}
        setCountryCodeSelection={setCountryCodeSelection}
        setTitle={setTitle}
        setFooterMessage={setFooterMessage}
      />
    ),
    // (props) => (
    //   <VerifyOTP
    //     {...props}
    //     {...registrationBackendProps}
    //     setInvitationName={setInvitationName}
    //     setTitle={setTitle}
    //     setFooterMessage={setFooterMessage}
    //   />
    // ),
    // (props) => (
    //   <UploadKTP
    //     {...props}
    //     {...registrationBackendProps}
    //     invitationName={invitationName}
    //     setTitle={setTitle}
    //     setFooterMessage={setFooterMessage}
    //   />
    // ),
    // (props) => (
    //   <RegisterBank
    //     {...props}
    //     {...registrationBackendProps}
    //     setTitle={setTitle}
    //     setFooterMessage={setFooterMessage}
    //   />
    // ),
    (props) => (
      <SetPasswordAndCreateUser
        {...props}
        {...registrationBackendProps}
        phoneNumber={handphone}
        countryCodeSelection={countryCodeSelection}
        setTitle={setTitle}
        setFooterMessage={setFooterMessage}
      />
    ),
  ] as RegistrationComponentRenderer[];

  const nodes = pages.map((renderComponent, pageIndex) => {
    return (
      <Route
        key={`route-registration-step-${pageIndex}`}
        path={`${baseRoute}/${pageIndex + 1}`}
      >
        <RegistrationSteps
          maxStep={pages.length}
          baseRoute={baseRoute}
          stepIndex={pageIndex + 1}
          renderComponent={renderComponent}
          loginRoute={loginRoute}
          onNextClickRef={onNextClickRef}
          registrationCompleteRoute={registrationCompleteRoute}
          title={title}
          footerMessage={footerMessage}
        />
      </Route>
    );
  });
  return <Switch>{nodes}</Switch>;
};
