import { Button, Card, Icon, Spinner } from "@blueprintjs/core";
import React, { useCallback, useEffect, useState } from "react";
import { CheckerConfirmTeamDialog } from "./CheckerConfirmTeamDialog";
import { useHistory } from "react-router-dom";
import {
  apiBaseUrl,
  DailyRecordStatus,
  DailyRecordType,
  FeedbackType,
  UserRoles,
} from "../../../../lib/sharedBackendModels";
import axios from "axios";
import { useToaster } from "../../../../utils/toaster";
import { TeamDataCard } from "../components/TeamDataCard";
import { RevisionReasonEntryDialog } from "./RevisionReasonEntryDialog";
import { PageComponent } from "../../lib";
import { CheckerSubmittedStatusDialog } from "./CheckerSubmittedStatusDialog";
import { dateFormatter } from "../../../../utils/formatters";
import { CheckerDeleteTeamDialog } from "./CheckerDeleteTeamDialog";

const styles = {
  bodySection: {
    textAlign: "center" as const,
    margin: "5% auto",
  },
  statusCard: {
    color: "orange",
    fontSize: "1rem",
  },
  title: {
    fontSize: "var(--theme-title-font-size)",
    fontWeight: "bold" as const,
    color: "var(--theme-purple)",
  },
  subtitle: {
    textAlign: "left" as const,
    textTransform: "capitalize" as const,
    padding: "2% 0%",
  },
  teamContainer: {
    display: "flex",
    flexDirection: "row" as const,
    justifyContent: "space-between",
  },
  button: {
    color: "white",
    background: "var(--theme-purple)",
    width: "100%",
    marginTop: "3%",
    fontFamily: "Poppins",
  },
  selectField: {
    border: "solid 1px var(--theme-medium-gray)",
    borderRadius: "5px",
    width: "100%",
    background: "none",
  },
};

export const Home: PageComponent = ({
  filteredCardDate,
  setUncheckedRecordNum,
  accessToken,
  role,
}) => {
  const hist = useHistory();
  const toaster = useToaster();

  const [isLoading, setIsLoading] = useState<boolean>(false);

  const [allDailyRecordsHomeData, setAllDailyRecordsHomeData] = useState<
    DailyRecordType[]
  >([]);
  const [currentDailyRecordData, setCurrentDailyRecordData] =
    useState<DailyRecordType | null>(null);
  const [unapprovedDailyRecordCount, setUnapprovedDailyRecordCount] =
    useState<number>(0);
  const [sumOfCrat, setSumOfCrat] = useState<number>(0);

  const [createFeedbackRequest, setCreateFeedbackRequest] =
    useState<FeedbackType>();

  const [isRevisionReasonEntryDialogOpen, setIsRevisionReasonEntryDialogOpen] =
    useState<boolean>(false);
  const [revisionReasonEntry, setRevisionReasonEntry] = useState<string>();

  const [isCheckerConfirmTeamDialogOpen, setIsCheckerConfirmTeamDialogOpen] =
    useState<boolean>(false);
  const [isCheckerDeleteTeamDialogOpen, setIsCheckerDeleteTeamDialogOpen] =
    useState<boolean>(false);
  const [
    isCheckerSubmittedStatusDialogOpen,
    setIsCheckerSubmittedStatusDialogOpen,
  ] = useState<boolean>(false);

  const [statusToShow, setStatusToShow] = useState<string | undefined>();

  const submitTeamProduction = useCallback(async () => {
    setIsLoading(true);
    setIsCheckerDeleteTeamDialogOpen(false);
    axios
      .post(`${apiBaseUrl}/feedback`, createFeedbackRequest, {
        headers: {
          "x-access-token": accessToken,
          "Access-Control-Allow-Origin": "*",
          "Content-Type": "application/json",
        },
      })
      .then((res) => {
        if (res.status === 200) {
          toaster?.current?.show({
            message: "feedback created",
            intent: "success",
          });
          setIsCheckerSubmittedStatusDialogOpen(true);
        }
      })
      .catch((e) => {
        toaster?.current?.show({
          message: `${e}`,
          intent: "warning",
        });
      })
      .finally(() => {
        setIsLoading(false);
        return hist.push(`dashboard/0`);
      });
  }, [createFeedbackRequest, setIsLoading, setUncheckedRecordNum]);

  const deleteTeamProduction = useCallback(async () => {
    setIsLoading(true);
    setIsCheckerConfirmTeamDialogOpen(false);
    setIsCheckerDeleteTeamDialogOpen(false);
    axios
      .delete(
        `${apiBaseUrl}/daily-record/` + createFeedbackRequest?.dailyRecordID,
        {
          headers: {
            "x-access-token": accessToken,
            "Access-Control-Allow-Origin": "*",
            "Content-Type": "application/json",
          },
        },
      )
      .then((res) => {
        if (res.status === 200) {
          toaster?.current?.show({
            message: "The record is deleted now",
            intent: "success",
          });
          setIsCheckerSubmittedStatusDialogOpen(true);
        }
      })
      .catch((e) => {
        toaster?.current?.show({
          message: `${e}`,
          intent: "warning",
        });
      })
      .finally(() => {
        setIsLoading(false);
        return hist.push(`dashboard/0`);
      });
  }, [createFeedbackRequest, setIsLoading, setUncheckedRecordNum]);

  useEffect(() => {
    setIsLoading(true);
    axios
      .get(`${apiBaseUrl}/daily-record`, {
        headers: {
          "x-access-token": accessToken,
          "Access-Control-Allow-Origin": "*",
        },
      })
      .then((res) => {
        if (res.status === 200 || res.status === 304) {
          const homeViewDailyRecord = res.data.dailyRecords
            .filter(
              (d: DailyRecordType) =>
                (d.status === DailyRecordStatus.Rejected &&
                  d.feedbackNum! < 4) ||
                d.status === DailyRecordStatus.Started ||
                d.status === DailyRecordStatus.Pending,
            )
            .filter(
              (d: DailyRecordType) =>
                (filteredCardDate
                  ? dateFormatter(d.RecordShift?.Shift.startDate) ===
                    dateFormatter(filteredCardDate)
                  : true) && (statusToShow ? d.status === statusToShow : true),
            );
          setAllDailyRecordsHomeData(homeViewDailyRecord);
        }
      })
      .catch((e) => {
        toaster?.current?.show({
          message: `${e}`,
          intent: "warning",
        });
      })
      .finally(() => setIsLoading(false));
  }, [submitTeamProduction, filteredCardDate, statusToShow, setStatusToShow]);
  useEffect(() => {
    const dailyRecordsNum = allDailyRecordsHomeData.length;
    setUnapprovedDailyRecordCount(dailyRecordsNum);
    setUncheckedRecordNum && setUncheckedRecordNum(dailyRecordsNum);

    setSumOfCrat(
      allDailyRecordsHomeData.reduce((acc, team) => {
        return (acc += team.productionCrat);
      }, 0),
    );
  }, [allDailyRecordsHomeData, setUnapprovedDailyRecordCount]);

  return (
    <>
      {isLoading && (
        <div style={{ position: "fixed", left: "45%" }}>
          <Spinner />
        </div>
      )}
      <RevisionReasonEntryDialog
        isOpen={isRevisionReasonEntryDialogOpen}
        onClose={() => setIsRevisionReasonEntryDialogOpen(false)}
        setIsRevisionReasonEntryDialogOpen={setIsRevisionReasonEntryDialogOpen}
        setRevisionReasonEntry={setRevisionReasonEntry}
        revisionReasonEntry={revisionReasonEntry}
        submitTeamProduction={submitTeamProduction}
      />
      <CheckerConfirmTeamDialog
        isOpen={isCheckerConfirmTeamDialogOpen}
        onClose={() => setIsCheckerConfirmTeamDialogOpen(false)}
        teamData={currentDailyRecordData}
        setIsCheckerConfirmTeamDialogOpen={setIsCheckerConfirmTeamDialogOpen}
        submitTeamProduction={submitTeamProduction}
        setIsCheckerSubmittedStatusDialogOpen={
          setIsCheckerSubmittedStatusDialogOpen
        }
        isCheckerSubmittedStatusDialogOpen={isCheckerSubmittedStatusDialogOpen}
      />
      <CheckerDeleteTeamDialog
        isOpen={isCheckerDeleteTeamDialogOpen}
        onClose={() => setIsCheckerDeleteTeamDialogOpen(false)}
        teamData={currentDailyRecordData}
        setIsCheckerDeleteTeamDialogOpen={setIsCheckerDeleteTeamDialogOpen}
        deleteTeamProduction={deleteTeamProduction}
        setIsCheckerSubmittedStatusDialogOpen={
          setIsCheckerSubmittedStatusDialogOpen
        }
        isCheckerSubmittedStatusDialogOpen={isCheckerSubmittedStatusDialogOpen}
      />
      <CheckerSubmittedStatusDialog
        isOpen={isCheckerSubmittedStatusDialogOpen}
        onClose={() => {
          setIsCheckerSubmittedStatusDialogOpen(false);
        }}
        setIsCheckerSubmittedStatusDialogOpen={
          setIsCheckerSubmittedStatusDialogOpen
        }
        revisionReasonEntry={!!revisionReasonEntry}
      />
      <div>
        <Card elevation={2}>
          <div style={styles.subtitle}>total crat yang berhasil di input</div>
          <div style={styles.title}>{sumOfCrat}</div>
        </Card>
        <div style={styles.bodySection}>
          <Card
            style={{
              display: "flex",
              flexDirection: "row",
              justifyContent: "space-around",
              marginBottom: "5%",
            }}
          >
            <div>
              <Icon icon="filter" style={{ verticalAlign: "middle" }} />
              status:
            </div>
            <select
              style={{ ...styles.selectField, width: "50%" }}
              onChange={(e) => {
                setStatusToShow(e.target.value);
              }}
              value={statusToShow}
            >
              <option value={""}></option>
              <option value={DailyRecordStatus.Started}>
                {DailyRecordStatus.Started}
              </option>
              <option value={DailyRecordStatus.Rejected}>
                {DailyRecordStatus.Rejected}
              </option>
            </select>
          </Card>
          {allDailyRecordsHomeData ? (
            <TeamDataCard
              allDailyRecordsData={allDailyRecordsHomeData.sort((a, b) =>
                a.createdAt! > b.createdAt! ? 1 : -1,
              )}
              setIsConfirmTeamDialogOpen={setIsCheckerConfirmTeamDialogOpen}
              setIsDeleteTeamDialogOpen={setIsCheckerDeleteTeamDialogOpen}
              setCurrentDailyRecordData={setCurrentDailyRecordData}
              currentDailyRecordData={currentDailyRecordData}
              isHomePage={true}
              setIsRevisionReasonEntryDialogOpen={
                setIsRevisionReasonEntryDialogOpen
              }
              IsRevisionReasonEntryDialogOpen={isRevisionReasonEntryDialogOpen}
              revisionReasonEntry={revisionReasonEntry}
              setRevisionReasonEntry={setRevisionReasonEntry}
              setCreateFeedbackRequest={setCreateFeedbackRequest}
              role={role}
            />
          ) : (
            !isLoading && (
              <div style={{ textAlign: "center" }}>Tidak ada record</div>
            )
          )}
          {role === UserRoles.Operator && (
            <Button
              style={styles.button}
              onClick={() => {
                hist.push({
                  pathname: "/dashboard/1",
                  state: { chosenDate: filteredCardDate },
                });
              }}
              text={"+ Input Harian"}
            />
          )}
        </div>
      </div>
    </>
  );
};
