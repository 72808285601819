import React, {
  FunctionComponent,
  useCallback,
  useEffect,
  useState,
} from "react";
import { RegistrationComponentProps } from "../lib";
import countryCodes from "../../../lib/countryCodes.json";
import { cleanPhoneNumber } from "../../../utils/formatters";

const styles = {
  inputField: {
    padding: "3% 5%",
    border: "solid 1px var(--theme-medium-gray)",
  },
};

export const RegisterHandphone: FunctionComponent<RegistrationComponentProps> =
  ({
    setNextIsDisabled,
    setRegistrationToken,
    onNextClickRef,
    countryCodeSelection,
    setCountryCodeSelection,
    phoneNumber: handphone,
    setPhoneNumber: setHandphone,
    setTitle: setTitle,
    setFooterMessage: setFooterMessage,
  }) => {
    const MIN_PHONE_NUMBER_LENGTH = 7;
    const MAX_PHONE_NUMBER_LENGTH = 15;

    const countryCodeSelections = countryCodes.map((countryCode, index) => {
      return (
        <option key={`country-code-option-${index}`}>{countryCode.dial}</option>
      );
    });

    const cleanHandphone = cleanPhoneNumber(handphone);
    const fullPhoneNumber = cleanPhoneNumber(
      countryCodeSelection + cleanHandphone,
    );

    useEffect(() => {
      setTitle("Masukkan nomor telepon Anda");
      setFooterMessage(`Pastikan nomor telepon anda aktif, dan dapat menerima
    pesan, karena kami akan mengirimkan kode OTP
    kepada nomor telepon anda`);
      return () => {
        setTitle("");
        setFooterMessage("");
      };
    }, []);

    useEffect(() => {
      setNextIsDisabled(
        !(
          fullPhoneNumber.length >= MIN_PHONE_NUMBER_LENGTH &&
          fullPhoneNumber.length <= MAX_PHONE_NUMBER_LENGTH
        ),
      );
    }, [fullPhoneNumber, setNextIsDisabled]);

    //hit send otp api
    const submit = useCallback(
      async () => {
        // const claim = await sendOTP({
        //   variables: {
        //     countryCode: parseInt(cleanPhoneNumber(countryCodeSelection)),
        //     phoneNumber: parseInt(cleanHandphone),
        //     token: "",
        //   },
        //   fetchPolicy: "no-cache",
        // });
        // setRegistrationToken(claim.data?.RegistrationSendOTP);
      },
      [
        // setRegistrationToken, sendOTP, countryCodeSelection, cleanHandphone
      ],
    );

    onNextClickRef.current = useCallback(async () => {
      try {
        await submit();
      } catch (e) {
        throw e;
      }
    }, [submit]);

    return (
      <div style={{ display: "flex", flexDirection: "row" }}>
        <select
          style={styles.inputField}
          onChange={(e) => setCountryCodeSelection(e.target.value)}
        >
          {countryCodeSelections}
        </select>
        <input
          style={{
            ...styles.inputField,
            ...{ width: "100%" },
          }}
          value={handphone}
          onChange={(e) => setHandphone(e.target.value)}
        />
      </div>
    );
  };
