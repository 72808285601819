import React, { FunctionComponent, useEffect, useState } from "react";
import { Redirect, Route, Switch } from "react-router-dom";
import { useFullScreenHandle } from "react-full-screen";
import { Login } from "./Login";
import { PwaDashboard as PwaDashboard } from "./Dashboard";
import "../index.css";
import { Cookies } from "react-cookie";

const App: FunctionComponent = () => {
  const fullScreenHandle = useFullScreenHandle();
  const [accessToken, setAccessToken] = useState<string>();
  const [role, setRole] = useState<string>();

  useEffect(() => {
    fullScreenHandle.enter();
  }, []);

  useEffect(() => {
    if (!accessToken) {
      const cookies = new Cookies();
      setAccessToken(cookies.get("token"));
      setRole(cookies.get("role"));
    }
  }, [setAccessToken, accessToken, setRole, role]);

  return (
    <Switch>
      {!accessToken ? (
        <>
          <Route path="/login">
            <Login
              baseRoute={"/login"}
              loggedInRoute={"/dashboard"}
              setAccessToken={setAccessToken}
              setRole={setRole}
            />
          </Route>
          <Route path={"/"}>
            <Redirect to={"/login"} />
          </Route>
        </>
      ) : (
        <>
          <Route path="/">
            <PwaDashboard
              baseUrl={"/dashboard"}
              accessToken={accessToken}
              role={role!}
            />
          </Route>
        </>
      )}
    </Switch>
  );
};

export default App;
