export const rupiahFormatter: (
  rp: number | string | undefined | null,
) => string = (rp) => {
  if (rp === undefined || rp === null) {
    return "0";
  }

  let srp = rp.toString().replace(/\./g, "").trim();
  const isNegative = srp[0] === "-";
  if (isNegative) {
    srp = srp.slice(1);
  }
  const out: string[] = [];
  let i = 0;
  for (const ch of srp.split("").reverse()) {
    if (i > 0 && i % 3 == 0 && i < srp.length) {
      out.push(".");
    }
    out.push(ch);
    i += 1;
  }
  return `${isNegative ? "-" : ""}${out.reverse().join("")}`;
};

const zeroPad = (i: number) => {
  return i < 10 ? `0${i}` : i.toString();
};

export const dateFormatter = (periodDateTime: Date | undefined | string) => {
  if (periodDateTime === undefined) {
    return "";
  }
  const periodDate = new Date(periodDateTime);
  const month = periodDate.getMonth() + 1;
  return `${periodDate.getFullYear()}-${zeroPad(month)}-${zeroPad(
    periodDate.getDate(),
  )}`;
};

export const timeFormatter = (periodDateTime: Date | undefined | string) => {
  if (periodDateTime === undefined) {
    return "";
  }
  const periodDate = new Date(periodDateTime);
  const amPm = periodDate.getHours() > 11 ? "PM" : "AM";
  const getMin =
    periodDate.getMinutes() < 10
      ? `0` + periodDate.getMinutes()
      : periodDate.getMinutes();
  return `${periodDate.getHours()}:${getMin}${amPm}`;
};

export const cleanPhoneNumber = (number: string) => {
  return number.replace(/[\W_]+/g, "");
};

export const localizeDateToJakarta = (date: Date) => {
  return new Date(
    (typeof date === "string" ? new Date(date) : date).toLocaleString("en-US", {
      timeZone: "Asia/Jakarta",
    }),
  );
};

export const getDayOfWeek = (date: Date | string) => {
  const localizedDate = new Date(date);
  const weekday = [
    "Minggu",
    "Senin",
    "Selasa",
    "Rabu",
    "Kamis",
    "Jumat",
    "Sabtu",
  ];
  return weekday[localizedDate.getDay()];
};
