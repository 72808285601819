import { Button } from "@blueprintjs/core";
import React, { FunctionComponent } from "react";
import { useHistory } from "react-router-dom";
import { DialogCard } from "../components/DialogCard";

const styles = {
  text: {
    margin: "10% auto",
  },
  button: {
    background: "var(--theme-purple)",
    color: "white",
  },
};
export const OperatorSubmittedStatusDialog: FunctionComponent<{
  isOpen: boolean;
  onClose: () => void;
  setIsOperatorSubmittedStatusDialogOpen: React.Dispatch<
    React.SetStateAction<boolean>
  >;
  setSelectedShift: React.Dispatch<
    React.SetStateAction<string | number | undefined>
  >;
  setSelectedTeamID: React.Dispatch<
    React.SetStateAction<string | number | undefined>
  >;
}> = ({
  isOpen,
  onClose,
  setIsOperatorSubmittedStatusDialogOpen,
  setSelectedShift,
  setSelectedTeamID,
}) => {
  const hist = useHistory();

  return (
    <DialogCard
      title={"berhasil"}
      isOpen={isOpen}
      onClose={onClose}
      icon={"tick-circle"}
    >
      <div style={styles.text}>Data berhasil disimpan</div>
      <Button
        style={styles.button}
        onClick={() => {
          setIsOperatorSubmittedStatusDialogOpen(false);
          setSelectedShift(0);
          setSelectedTeamID(0);
          hist.push("/dashboard/0");
        }}
        text={"ok"}
      />
    </DialogCard>
  );
};
