import {
  Button,
  Card,
  Elevation,
  FormGroup,
  InputGroup,
} from "@blueprintjs/core";
import axios from "axios";
import React, { FunctionComponent, useState } from "react";
import { useHistory } from "react-router-dom";
import logo from "../../../../src/logo.svg";
import { useToaster } from "../../../utils/toaster";
import { Cookies } from "react-cookie";
import { apiBaseUrl } from "../../../lib/sharedBackendModels";
const styles = {
  card: {
    margin: "10%",
    padding: "10%",
  },
  header: {
    textAlign: "center" as const,
    color: "var(--theme-green)",
    fontWeight: "bolder" as const,
    letterSpacing: "0.2rem" as const,
    padding: "5% 0%",
  },
  logo: {
    width: "25%",
  },
  body: { display: "flex", flexDirection: "column" as const, padding: "5% 0%" },
  footer: {
    display: "flex",
    flexDirection: "row" as const,
    justifyContent: "space-between" as const,
    padding: "10% 0%",
  },
};
export const LoginPage: FunctionComponent<{
  baseRoute: string;
  setAccessToken: React.Dispatch<React.SetStateAction<string | undefined>>;
  setRole: React.Dispatch<React.SetStateAction<string | undefined>>;
}> = ({ baseRoute, setAccessToken, setRole }) => {
  const [username, setUsername] = useState<string>("");
  const [password, setPassword] = useState<string>("");

  const canSubmit = !(username === "" || password === "");

  const hist = useHistory();
  const toaster = useToaster();

  const onSubmitClick = async () => {
    const userRequest = {
      phone: username,
      password: password,
    };

    await axios
      .post(`${apiBaseUrl}/user/auth/signin`, userRequest, {
        headers: {
          "Access-Control-Allow-Origin": "*",
          credentials: "include",
        },
      })
      .then((res) => {
        const response = JSON.parse(JSON.stringify(res));
        if (response.status === 200) {
          toaster?.current?.show({
            message: "logged in!",
            intent: "success",
          });
          const cookies = new Cookies();
          cookies.set("token", response.data.accessToken, {
            domain: window.location.hostname,
            path: "/",
            secure: false, // <-- false here when served over HTTP
          });
          cookies.set("role", response.data.role, {
            domain: window.location.hostname,
            path: "/",
            secure: false, // <-- false here when served over HTTP
          });
          setAccessToken(response.data.accessToken);
          setRole(response.data.role);
          return hist.push(`${baseRoute}/dashboard`);
        }
      })
      .catch((e) => {
        const err = JSON.parse(JSON.stringify(e));
        toaster?.current?.show({
          message: `Email or password are incorrect!`,
          intent: "warning",
        });
        return hist.push(`${baseRoute}`);
      });
  };

  return (
    <Card style={styles.card} elevation={Elevation.FOUR}>
      <div style={styles.header}>
        <div>
          <img style={styles.logo} src={logo} alt="gajiku-logo" />
        </div>
        Gajiku
      </div>
      <div style={styles.body}>
        <FormGroup label={"Nomor Telepon"}>
          <InputGroup
            value={username}
            onChange={(event) => setUsername(event.target.value.trim())}
          />
        </FormGroup>
        <FormGroup label={"Password"}>
          <InputGroup
            type={"password"}
            value={password}
            onChange={(event) => setPassword(event.target.value.trim())}
          />
        </FormGroup>
        <Button
          disabled={!canSubmit}
          text="Submit"
          onClick={onSubmitClick}
          style={{ fontFamily: "Poppins" }}
        />
      </div>
      {/* <div style={styles.footer}>
        <div>Lupa password?</div>
        <div
          style={{ cursor: "pointer" }}
          onClick={() => hist.push(`${baseRoute}/registration/1`)}
        >
          Daftar
          <Icon
            icon={"arrow-right"}
            style={{ verticalAlign: "middle" }}
            iconSize={10}
          />
        </div>
      </div> */}
    </Card>
  );
};
