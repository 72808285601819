import { Card } from "@blueprintjs/core";
import React, { FunctionComponent } from "react";
import { DailyRecordType, TeamType } from "../../../../lib/sharedBackendModels";

const styles = {
  card: {
    marginBottom: "2%",
    color: "var(--theme-purple)",
    fontWeight: "bold" as const,
  },
  selectField: {
    lineHeight: 1.5,
    padding: "2%",
    border: "none",
    boxShadow: "1px 1px 5px var(--theme-medium-gray)",
    borderRadius: "5px",
    width: "100%",
    background: "none",
  },
  formLabel: {
    margin: "7% 0% 2% 0%",
    fontSize: "0.7rem",
  },
  rowFlex: {
    display: "flex",
    flexDirection: "row" as const,
    verticalAlign: "bottom",
    margin: "1% 0%",
  },
};

export const ProductionInputCard: FunctionComponent<{
  setSelectedTeamID: React.Dispatch<
    React.SetStateAction<string | number | undefined>
  >;
  selectedTeamID: string | number | undefined;
  setFirstUserProdCount: React.Dispatch<
    React.SetStateAction<number | undefined>
  >;
  secondUserProdCount: number | undefined;
  setSecondUserProdCount: React.Dispatch<
    React.SetStateAction<number | undefined>
  >;
  firstUserProdCount: number | undefined;
  targetDailyRecordForRevision: DailyRecordType;
  allTeamsData?: TeamType[] | [];
  selectedTeam: TeamType;
}> = ({
  setSelectedTeamID,
  selectedTeamID,
  setSecondUserProdCount,
  secondUserProdCount,
  setFirstUserProdCount,
  firstUserProdCount,
  targetDailyRecordForRevision,
  allTeamsData,
  selectedTeam,
}) => {
  const teamList = allTeamsData?.map((team) => {
    return (
      <option key={team.id} value={`${team.id}`}>
        {`${team.name} - ${team.TeamMemberships[0].User.name}, ${team.TeamMemberships[1].User.name}`}
      </option>
    );
  });

  const initialUserOneCratCount =
    targetDailyRecordForRevision?.TeamRecord.Team.TeamMemberships[0].User.RecordResults.filter(
      (r) => {
        return r.dRecordID === targetDailyRecordForRevision?.id;
      },
    )[0]?.cratReached;
  const initialUserTwoCratCount =
    targetDailyRecordForRevision?.TeamRecord.Team.TeamMemberships[1].User.RecordResults.filter(
      (r) => {
        return r.dRecordID === targetDailyRecordForRevision?.id;
      },
    )[0]?.cratReached;

  allTeamsData?.sort((a, b) => (a.name > b.name ? 1 : -1)); //Sorting an array of teams by team names. Ex: team A, team B, team C, ...
  return (
    <Card elevation={2} style={styles.card}>
      <div>Tim</div>
      {targetDailyRecordForRevision?.TeamRecord?.Team ? (
        <select style={styles.selectField} disabled={true}>
          <option>{`${targetDailyRecordForRevision.TeamRecord.Team.name} - ${targetDailyRecordForRevision.TeamRecord.Team.TeamMemberships[0].User.name}, ${targetDailyRecordForRevision.TeamRecord.Team.TeamMemberships[1].User.name}`}</option>
        </select>
      ) : (
        <select
          style={styles.selectField}
          onChange={(e) => setSelectedTeamID(e.target.value)}
          value={selectedTeamID}
        >
          <option key={"null"} value={0}></option>
          {allTeamsData && teamList}
        </select>
      )}
      {selectedTeam && (
        <div style={{ display: "flex", flexDirection: "column" }}>
          <div
            style={{
              ...styles.rowFlex,
              ...styles.formLabel,
              justifyContent: "space-between",
            }}
          >
            <div>Members Of {selectedTeam.name}</div>
            <div style={{ color: "gray" }}>Hitungan Crat</div>
          </div>
          <div style={styles.rowFlex}>
            <input
              style={{
                ...styles.selectField,
                width: "65%",
                background: "var(--theme-light-gray)",
              }}
              disabled={true}
              value={selectedTeam.TeamMemberships[0].User.name}
            />
            <input
              style={{
                ...styles.selectField,
                width: "35%",
                marginLeft: "2%",
              }}
              placeholder={initialUserOneCratCount?.toString() || "Jumlah"}
              onChange={(e) =>
                setFirstUserProdCount(parseInt(e.target.value.trim()))
              }
              value={firstUserProdCount || initialUserOneCratCount}
              type="number"
              min={0}
            />
          </div>
          <div style={styles.rowFlex}>
            <input
              style={{
                ...styles.selectField,
                width: "65%",
                background: "var(--theme-light-gray)",
              }}
              disabled={true}
              value={selectedTeam.TeamMemberships[1].User.name}
            />
            <input
              style={{
                ...styles.selectField,
                width: "35%",
                marginLeft: "2%",
              }}
              placeholder={initialUserTwoCratCount?.toString() || "Jumlah"}
              onChange={(e) =>
                setSecondUserProdCount(parseInt(e.target.value.trim()))
              }
              value={secondUserProdCount || initialUserTwoCratCount}
              type="number"
              min={0}
            />
          </div>
        </div>
      )}
    </Card>
  );
};
