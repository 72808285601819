import React, {
  createContext,
  FunctionComponent,
  RefObject,
  useContext,
  useRef,
} from "react";
import { Position, Toaster } from "@blueprintjs/core";

const ToasterContext = createContext<RefObject<Toaster> | undefined>(undefined);

export const ToasterProvider: FunctionComponent = ({ children }) => {
  const toaster = useRef<Toaster | null>(null);
  return (
    <>
      <ToasterContext.Provider value={toaster}>
        <Toaster ref={(ref) => (toaster.current = ref)} />
        {children}
      </ToasterContext.Provider>
    </>
  );
};

export const useToaster = () => useContext(ToasterContext);

export interface ToastResponse {
  resp?: any;
  error?: Error;
}

export const useToastHandler = Toaster.create({
  className: "recipe-toaster",
  position: Position.TOP,
  autoFocus: true,
  canEscapeKeyClear: true,
});
