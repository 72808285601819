import React, { FunctionComponent } from "react";
import { Route, Switch } from "react-router-dom";
import { LoginPage } from "./LoginPage";
import { RegistrationPage } from "./RegistrationPage";

export const Login: FunctionComponent<{
  baseRoute: string;
  loggedInRoute: string;
  setAccessToken: React.Dispatch<React.SetStateAction<string | undefined>>;
  setRole: React.Dispatch<React.SetStateAction<string | undefined>>;
}> = ({ baseRoute, loggedInRoute, setAccessToken, setRole }) => {
  return (
    <Switch>
      <Route exact path={`${baseRoute}`}>
        <LoginPage
          baseRoute={`${baseRoute}`}
          setAccessToken={setAccessToken}
          setRole={setRole}
        />
      </Route>
      <Route path={`${baseRoute}/registration`}>
        <RegistrationPage
          baseRoute={`${baseRoute}/registration`}
          loginRoute={baseRoute}
          registrationCompleteRoute={loggedInRoute}
        />
      </Route>
      {/* <Route path={`${baseRoute}/forget-password`}>
          <ForgetPasswordPage
            baseRoute={`${baseRoute}/forget-password`}
            loginRoute={baseRoute}
            registrationCompleteRoute={loggedInRoute}
          />
        </Route> */}
    </Switch>
  );
};
