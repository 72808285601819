import { Button, TextArea } from "@blueprintjs/core";
import React, { FunctionComponent } from "react";
import { DialogCard } from "../components/DialogCard";

const styles = {
  buttonContainer: {
    display: "flex",
    flexDirection: "row" as const,
    width: "100%",
    marginTop: "2%",
    justifyContent: "center",
  },
  button: {
    width: "100%",
    color: "var(--theme-purple)",
    margin: "0% 1%",
    fontFamily: "Poppins",
  },
};

export const RevisionReasonEntryDialog: FunctionComponent<{
  isOpen: boolean;
  onClose: () => void;
  setIsRevisionReasonEntryDialogOpen: React.Dispatch<
    React.SetStateAction<boolean>
  >;
  setRevisionReasonEntry: React.Dispatch<
    React.SetStateAction<string | undefined>
  >;
  revisionReasonEntry: string | undefined;
  submitTeamProduction: () => void;
}> = ({
  isOpen,
  onClose,
  setIsRevisionReasonEntryDialogOpen,
  setRevisionReasonEntry,
  revisionReasonEntry,
  submitTeamProduction,
}) => {
  return (
    <DialogCard title={"Alasan Revisi"} isOpen={isOpen} onClose={onClose}>
      <TextArea
        onChange={(e) => setRevisionReasonEntry(e.target.value)}
        value={revisionReasonEntry}
      />
      <div style={styles.buttonContainer}>
        <Button
          style={styles.button}
          text="cancel"
          onClick={() => {
            setRevisionReasonEntry("");
            setIsRevisionReasonEntryDialogOpen(false);
          }}
        />
        <Button
          style={{
            ...styles.button,
            color: !revisionReasonEntry ? "gray" : "var(--theme-purple)",
          }}
          text="submit"
          disabled={!revisionReasonEntry}
          onClick={() => {
            submitTeamProduction();
            setIsRevisionReasonEntryDialogOpen(false);
          }}
        />
      </div>
    </DialogCard>
  );
};
