import { FormGroup, InputGroup } from "@blueprintjs/core";
import axios from "axios";
import React, {
  FunctionComponent,
  useCallback,
  useEffect,
  useState,
} from "react";
import { apiBaseUrl, UserRoles } from "../../../lib/sharedBackendModels";
import { cleanPhoneNumber } from "../../../utils/formatters";
import { useToaster } from "../../../utils/toaster";
import { RegistrationComponentProps } from "../lib";

const styles = {
  passwordInput: {
    width: "100%",
    display: "block",
    padding: "7% 5%",
  },
};
export const SetPasswordAndCreateUser: FunctionComponent<RegistrationComponentProps> =
  ({
    setNextIsDisabled,
    onNextClickRef,
    phoneNumber,
    countryCodeSelection,
    setTitle,
  }) => {
    const toaster = useToaster();

    const [initialPassword, setInitialPassword] = useState<string>("");
    const [password, setPassword] = useState<string>("");

    useEffect(() => {
      setTitle("Buat password Anda dengan aman.");
      return () => {
        setTitle("");
      };
    }, []);

    useEffect(() => {
      setNextIsDisabled(
        password === "" || password.length < 4 || password !== initialPassword,
      );
    }, [password, setNextIsDisabled]);

    const cleanFullPhoneNumber = cleanPhoneNumber(
      countryCodeSelection + phoneNumber,
    );

    onNextClickRef.current = useCallback(async () => {
      const userRequest = {
        phone: cleanFullPhoneNumber,
        password: password,
      };
      axios
        .post(
          `${apiBaseUrl}/user/auth/signup`,
          { ...userRequest, role: UserRoles.Checker },
          {
            headers: {
              "Access-Control-Allow-Origin": "*",
            },
          },
        )
        .then((res) => {
          if (res.status === 200) {
            toaster?.current?.show({
              message: "user created!",
              intent: "success",
            });
            axios.post(`${apiBaseUrl}/user/auth/signin`, userRequest, {
              headers: {
                "Access-Control-Allow-Origin": "*",
                credentials: "include",
              },
            });
          }
        });
    }, [password]);

    return (
      <div>
        <FormGroup label={"Password"}>
          <InputGroup
            style={styles.passwordInput}
            type="password"
            value={initialPassword}
            onChange={(e) => setInitialPassword(e.target.value)}
          />
        </FormGroup>
        <FormGroup label={"Ulangi Password"}>
          <InputGroup
            style={styles.passwordInput}
            type="password"
            value={password}
            onChange={(e) => setPassword(e.target.value)}
          />
        </FormGroup>
      </div>
    );
  };
