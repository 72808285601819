import { Button, Card, Spinner, TextArea } from "@blueprintjs/core";
import axios from "axios";
import React, { useCallback, useEffect, useState } from "react";
import { useHistory, useLocation } from "react-router-dom";
import {
  apiBaseUrl,
  DailyRecordRequestBodyType,
  DailyRecordStatus,
  DailyRecordType,
  ShiftType,
  TeamType,
} from "../../../../lib/sharedBackendModels";
import { dateFormatter, timeFormatter } from "../../../../utils/formatters";
import { useToaster } from "../../../../utils/toaster";
import { PageComponent } from "../../lib";
import { OperatorSubmittedStatusDialog } from "./OperatorSubmittedStatusDialog";
import { ProductionInputCard } from "./TeamProductionInputCard";

const styles = {
  card: {
    marginBottom: "2%",
    color: "var(--theme-purple)",
    fontWeight: "bold" as const,
  },
  selectField: {
    lineHeight: 1.5,
    padding: "2%",
    border: "none",
    boxShadow: "1px 1px 5px var(--theme-medium-gray)",
    borderRadius: "5px",
    width: "100%",
    background: "none",
  },
  formLabel: {
    margin: "7% 0% 2% 0%",
    fontSize: "0.7rem",
  },
  button: {
    width: "100%",
    marginTop: "2%",
    fontFamily: "Poppins",
  },
};

export interface IShift {
  shiftID: string;
  startDate: string;
  endDate: string;
}
export interface IUserTeam {
  teamID: string;
  teamName: string;
  users: { name: string; id: string }[];
}

export const Production: PageComponent = ({
  onTopIconClickRef,
  accessToken,
}) => {
  const toaster = useToaster();
  const [selectedShift, setSelectedShift] = useState<
    string | number | undefined
  >();
  const [selectedTeamID, setSelectedTeamID] = useState<
    string | number | undefined
  >();
  const [firstUserProdCount, setFirstUserProdCount] = useState<number>();
  const [secondUserProdCount, setSecondUserProdCount] = useState<number>();
  const [
    isOperatorSubmittedStatusDialogOpen,
    setIsOperatorSubmittedStatusDialogOpen,
  ] = useState<boolean>(false);

  const sumOfCrat = (firstUserProdCount: number, secondUserProdCount: number) =>
    selectedTeamID === 0 ? 0 : firstUserProdCount + secondUserProdCount;

  const [allShiftsData, setAllShiftsData] = useState<ShiftType[]>([]);
  const [allTeamsData, setAllTeamsData] = useState<TeamType[]>([]);
  const [unitID, setUnitID] = useState<string>();

  const [dailyRecordRequest, setDailyRecordRequest] =
    useState<DailyRecordRequestBodyType>();

  const hist = useHistory();
  // eslint-disable-next-line @typescript-eslint/no-empty-function
  onTopIconClickRef.current = () => {
    if (targetDailyRecordForRevision) {
      setFirstUserProdCount(initialUserOneCratCount);
      setSecondUserProdCount(initialUserTwoCratCount);
    } else {
      setFirstUserProdCount(undefined);
      setSecondUserProdCount(undefined);
      setSelectedShift(0);
      setSelectedTeamID(0);
    }
  };

  const [isLoading, setIsLoading] = useState<boolean>(false);
  const location = useLocation();
  const targetDailyRecordForRevision: DailyRecordType =
    location.state && (location.state as any).targetDailyRecordForRevision;
  const chosenDate: any = location.state && (location.state as any).chosenDate;
  const currentDate = new Date().toLocaleDateString();
  const inputDate: string = chosenDate
    ? chosenDate.toLocaleDateString()
    : currentDate;
  useEffect(() => {
    setIsLoading(true);
    axios
      .get(`${apiBaseUrl}/shift`, {
        headers: {
          "Access-Control-Allow-Origin": "*",
          "x-access-token": accessToken,
        },
      })
      .then((res) => {
        if (res.status === 200 || res.status === 304) {
          setAllShiftsData(res.data.shifts);
        }
      })
      .catch((e) => {
        toaster?.current?.show({
          message: `${e}`,
          intent: "warning",
        });
      });

    axios
      .get(`${apiBaseUrl}/team`, {
        headers: {
          "Access-Control-Allow-Origin": "*",
          "x-access-token": accessToken,
        },
      })
      .then((res) => {
        if (res.status === 200 || res.status === 304) {
          setAllTeamsData(res.data.teams);
        }
      })
      .catch((e) => {
        toaster?.current?.show({
          message: `${e}`,
          intent: "warning",
        });
      });

    axios
      .get(`${apiBaseUrl}/unit`, {
        headers: {
          "Access-Control-Allow-Origin": "*",
          "x-access-token": accessToken,
        },
      })
      .then((res) => {
        if (res.status === 200 || res.status === 304) {
          setUnitID(res.data.units[0].id);
        }
      })
      .catch((e) => {
        toaster?.current?.show({
          message: `${e}`,
          intent: "warning",
        });
      });
    setIsLoading(false);
  }, [setAllShiftsData, setAllTeamsData, setUnitID, setIsLoading]);
  const formattedInputDate =
    dateFormatter(inputDate).slice(0, 5) +
    dateFormatter(inputDate).slice(8, 10) +
    dateFormatter(inputDate).slice(4, 7);
  let shiftExists = false;
  const inputDateFormatted = inputDate.replace(/\./g, "/");
  const dateParts = inputDateFormatted.split("/");
  // month is 0-based, that's why we need dataParts[1] - 1
  let dateChosen: any;
  let check: any;
  if (inputDate.indexOf("/") == -1) {
    dateChosen = new Date(+dateParts[2], +dateParts[1] - 1, +dateParts[0] + 1)
      .toISOString()
      .slice(0, 10);
  } else {
    check = "HEREE";
    dateChosen = new Date(+dateParts[2], +dateParts[0] - 1, +dateParts[1] + 1)
      .toISOString()
      .slice(0, 10);
  }
  allShiftsData?.map((shift) => {
    const dateGiven = new Date(shift.startDate);
    if (
      new Date(dateChosen).setUTCHours(0, 0, 0, 0) ==
      dateGiven.setUTCHours(0, 0, 0, 0)
    ) {
      shiftExists = true;
      return;
    }
  });

  const shiftList = allShiftsData?.map((shift) => {
    const dateGiven = new Date(shift.startDate);
    if (shiftExists) {
      if (
        new Date(dateChosen).setUTCHours(0, 0, 0, 0) ==
        dateGiven.setUTCHours(0, 0, 0, 0)
      ) {
        return (
          <option key={shift.id} value={`${shift.id}`}>
            {`${dateFormatter(shift.startDate)}, ${timeFormatter(
              shift.startDate,
            )} s/d ${timeFormatter(shift.startDate)}`}
          </option>
        );
      }
    } else {
      let currDate = new Date().toISOString().slice(0, 10);
      currDate =
        dateFormatter(currDate).slice(0, 5) +
        dateFormatter(currDate).slice(8, 10) +
        dateFormatter(currDate).slice(4, 7);

      if (formattedInputDate == currDate) {
        return (
          <option key={shift.id} value={`${shift.id}`}>
            {`${dateFormatter(shift.startDate)}, ${timeFormatter(
              shift.startDate,
            )} s/d ${timeFormatter(shift.startDate)}`}
          </option>
        );
      }
    }
  });

  const selectedTeam = allTeamsData?.filter((team) => {
    if (targetDailyRecordForRevision) {
      return team.id === targetDailyRecordForRevision?.TeamRecord?.Team.id;
    } else {
      return team.id === selectedTeamID;
    }
  })[0];
  const initialUserOneCratCount =
    targetDailyRecordForRevision?.TeamRecord.Team.TeamMemberships[0].User.RecordResults.filter(
      (r) => {
        return r.dRecordID === targetDailyRecordForRevision?.id;
      },
    )[0]?.cratReached;
  const initialUserTwoCratCount =
    targetDailyRecordForRevision?.TeamRecord.Team.TeamMemberships[1].User.RecordResults.filter(
      (r) => {
        return r.dRecordID === targetDailyRecordForRevision?.id;
      },
    )[0]?.cratReached;

  useEffect(() => {
    if (targetDailyRecordForRevision) {
      if (!firstUserProdCount) {
        setFirstUserProdCount(initialUserOneCratCount);
        setSecondUserProdCount(initialUserTwoCratCount);
      }
    }
    const dailyRecordRequestBody = {
      productionCrat: firstUserProdCount! + secondUserProdCount!,
      teamID: selectedTeamID,
      shiftID: selectedShift,
      unitID: unitID,
      status: targetDailyRecordForRevision
        ? DailyRecordStatus.Pending
        : DailyRecordStatus.Started,
      userProduction: [
        {
          userID: selectedTeam?.TeamMemberships[0].userID,
          productionCrat: firstUserProdCount,
        },
        {
          userID: selectedTeam?.TeamMemberships[1].userID,
          productionCrat: secondUserProdCount,
        },
      ],
    } as DailyRecordRequestBodyType;

    setDailyRecordRequest(dailyRecordRequestBody);
  }, [
    firstUserProdCount,
    secondUserProdCount,
    setFirstUserProdCount,
    setSecondUserProdCount,
    selectedTeamID,
    selectedShift,
    selectedTeam,
  ]);

  const updateDailyRecord = useCallback(async () => {
    setIsLoading(true);
    if (targetDailyRecordForRevision) {
      axios
        .all([
          axios.put(
            `${apiBaseUrl}/record-result/${dailyRecordRequest?.userProduction[0].userID}/${targetDailyRecordForRevision.id}`,
            {
              cratReached: firstUserProdCount,
            },
            {
              headers: {
                "Access-Control-Allow-Origin": "*",
                "x-access-token": accessToken,
                "Content-Type": "application/json",
              },
            },
          ),
          axios.put(
            `${apiBaseUrl}/record-result/${dailyRecordRequest?.userProduction[1].userID}/${targetDailyRecordForRevision.id}`,
            {
              cratReached: secondUserProdCount,
            },
            {
              headers: {
                "Access-Control-Allow-Origin": "*",
                "x-access-token": accessToken,
                "Content-Type": "application/json",
              },
            },
          ),
          axios.put(
            `${apiBaseUrl}/daily-record/${targetDailyRecordForRevision.id}`,
            dailyRecordRequest,
            {
              headers: {
                "Access-Control-Allow-Origin": "*",
                "x-access-token": accessToken,
                "Content-Type": "application/json",
              },
            },
          ),
        ])
        .then(
          axios.spread((...res) => {
            if (
              res[0].status === 200 &&
              res[1].status === 200 &&
              res[2].status === 200
            ) {
              toaster?.current?.show({
                message: "record resubmitted",
                intent: "success",
              });
              setIsOperatorSubmittedStatusDialogOpen(true);
            }
          }),
        )
        .catch((e) => {
          toaster?.current?.show({
            message: `${e}; resubmit record immediately to prevent inconsistent data.`,
            intent: "danger",
          });
        })
        .finally(() => {
          setIsLoading(false);
        });
    }
  }, [
    dailyRecordRequest,
    targetDailyRecordForRevision,
    firstUserProdCount,
    secondUserProdCount,
    setFirstUserProdCount,
    setSecondUserProdCount,
  ]);

  const submitDailyRecord = useCallback(async () => {
    axios
      .post(`${apiBaseUrl}/daily-record`, dailyRecordRequest, {
        headers: {
          "Access-Control-Allow-Origin": "*",
          "x-access-token": accessToken,
          "Content-Type": "application/json",
        },
      })
      .then((res) => {
        if (res.status === 200) {
          toaster?.current?.show({
            message: "record submitted",
            intent: "success",
          });

          setIsOperatorSubmittedStatusDialogOpen(true);
        } else {
          toaster?.current?.show({
            message: `${res.statusText}; please try again later.`,
            intent: "warning",
          });
        }
      });
  }, [dailyRecordRequest]);

  const disallowSubmitRecordRequest = targetDailyRecordForRevision
    ? !!(
        (firstUserProdCount || firstUserProdCount == 0) &&
        (secondUserProdCount || secondUserProdCount == 0) &&
        firstUserProdCount === initialUserOneCratCount &&
        secondUserProdCount === initialUserTwoCratCount
      )
    : !(
        (firstUserProdCount || firstUserProdCount == 0) &&
        (secondUserProdCount || secondUserProdCount == 0) &&
        selectedShift
      );
  return (
    <div>
      {isLoading && (
        <div style={{ position: "fixed", left: "45%" }}>
          <Spinner />
        </div>
      )}
      <OperatorSubmittedStatusDialog
        isOpen={isOperatorSubmittedStatusDialogOpen}
        onClose={() => {
          setIsOperatorSubmittedStatusDialogOpen(false);
        }}
        setIsOperatorSubmittedStatusDialogOpen={
          setIsOperatorSubmittedStatusDialogOpen
        }
        setSelectedShift={setSelectedShift}
        setSelectedTeamID={setSelectedTeamID}
      />
      <Card elevation={2} style={styles.card}>
        <div>Input Date</div>
        <div>{inputDate}</div>
      </Card>
      <Card elevation={2} style={styles.card}>
        <div>Shift</div>
        {targetDailyRecordForRevision?.RecordShift ? (
          <select style={styles.selectField} disabled={true}>
            <option>
              {`${dateFormatter(
                targetDailyRecordForRevision.RecordShift.Shift.startDate,
              )}, ${timeFormatter(
                targetDailyRecordForRevision.RecordShift.Shift.startDate,
              )} s/d ${timeFormatter(
                targetDailyRecordForRevision.RecordShift.Shift.endDate,
              )}`}
            </option>
          </select>
        ) : (
          <select
            style={styles.selectField}
            onChange={(e) => setSelectedShift(e.target.value)}
            value={selectedShift}
          >
            <option key={"null"} value={0}></option>
            {shiftList}
          </select>
        )}
      </Card>
      <ProductionInputCard
        setSelectedTeamID={setSelectedTeamID}
        selectedTeamID={selectedTeamID}
        setSecondUserProdCount={setSecondUserProdCount}
        secondUserProdCount={secondUserProdCount}
        setFirstUserProdCount={setFirstUserProdCount}
        firstUserProdCount={firstUserProdCount}
        targetDailyRecordForRevision={targetDailyRecordForRevision}
        allTeamsData={allTeamsData}
        selectedTeam={selectedTeam}
      />
      <Card elevation={2} style={styles.card}>
        Total Crat
        <div style={{ fontSize: "var(--theme-title-font-size)" }}>
          {targetDailyRecordForRevision
            ? sumOfCrat(
                firstUserProdCount || initialUserOneCratCount,
                secondUserProdCount || initialUserTwoCratCount,
              )
            : sumOfCrat(firstUserProdCount || 0, secondUserProdCount || 0)}
        </div>
      </Card>
      {targetDailyRecordForRevision && (
        <Card elevation={2} style={styles.card}>
          <div>Alasan Revisi</div>
          <TextArea
            style={{ width: "100%" }}
            disabled={true}
            placeholder={
              targetDailyRecordForRevision.Feedbacks.filter(
                (f) => f.dailyRecordID === targetDailyRecordForRevision.id,
              )[0]?.comment || "nil"
            }
          />
        </Card>
      )}
      <Card elevation={2} style={styles.card}>
        <Button
          text={targetDailyRecordForRevision ? "Ajukan Revisi" : "Submit"}
          style={
            !disallowSubmitRecordRequest
              ? {
                  ...styles.button,
                  background: "var(--theme-purple)",
                  color: "white",
                }
              : styles.button
          }
          disabled={disallowSubmitRecordRequest}
          onClick={
            dailyRecordRequest &&
            (targetDailyRecordForRevision
              ? updateDailyRecord
              : submitDailyRecord)
          }
        />
      </Card>
    </div>
  );
};
