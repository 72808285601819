import React, { FunctionComponent, useRef, useState } from "react";
import { Icon } from "@blueprintjs/core";
import { IconNames } from "@blueprintjs/icons";
import {
  Switch,
  Route,
  Redirect,
  useHistory,
  useLocation,
} from "react-router-dom";
import { Production } from "./Pages/Production";
import { Home } from "./Pages/Home";
import { Profile } from "./Pages/Profile";
import { History } from "./Pages/History";
import { IPage } from "./lib";
import logo from "../../../src/logo.svg";
import { getDayOfWeek } from "../../utils/formatters";
import { DateInput } from "@blueprintjs/datetime";

const styles = {
  homeHeader: {
    background: "linear-gradient(var(--theme-purple), rgb(67, 63, 121))",
    height: "20vh",
    display: "flex" as const,
    flexDirection: "row" as const,
    fontSize: "2.5rem" as const,
    fontWeight: "bold" as const,
    justifyContent: "space-between",
    color: "var(--theme-light-gray)",
    padding: "10%",
    marginBottom: "-3vh",
    lineHeight: 1,
  },
  header: {
    height: "10vh",
    display: "flex" as const,
    flexDirection: "row" as const,
    justifyContent: "space-between",
    padding: "13% 10% 12% 10%",
    background: "white",
    fontSize: "var(--theme-title-font-size)",
    fontWeight: "bold" as const,
    marginBottom: "1vh",
  },
  body: {
    padding: "0% 5%",
    overflowY: "scroll" as const,
    paddingBottom: "10vh" as const,
  },
  headerIcon: {
    justifyContent: "flex-end",
    display: "flex" as const,
    flexDirection: "row" as const,
  },
  link: {
    display: "flex",
    flexDirection: "column" as const,
    justifyContent: "center" as const,
    fontSize: "10px",
    textAlign: "center" as const,
    width: "20%",
  },
  footer: {
    background: "white",
    position: "fixed" as const,
    bottom: "0",
    left: "0",
    height: "10%",
    display: "flex",
    flexDirection: "row" as const,
    width: "100%",
  },
  footerIcon: {
    paddingBottom: "3%",
    verticalAlign: "unset",
  },
};

export const PwaDashboard: FunctionComponent<{
  baseUrl: string;
  accessToken: string;
  role: string;
}> = ({ baseUrl, accessToken, role }) => {
  const hist = useHistory();
  const [filteredCardDate, setFilteredCardDate] = useState<Date>();
  const [uncheckedRecordNum, setUncheckedRecordNum] = useState<number>();
  const pages = [
    {
      component: Home,
      navIcon: (
        <img src={logo} alt={"gajiku-logo"} style={{ width: "3.2em" }} />
      ),
      navButtonLabel: "Home",
      topIcon: IconNames.NOTIFICATIONS,
      pageTitle: (
        <div>
          {filteredCardDate ? getDayOfWeek(filteredCardDate) : "HOME"}
          <div>
            <DateInput
              placeholder="Pilih tanggal"
              formatDate={(date) => date.toLocaleDateString()}
              parseDate={(str) => new Date(str)}
              maxDate={new Date()}
              onChange={(selectedDate) => setFilteredCardDate(selectedDate)}
            />
          </div>
        </div>
      ),
    },
    {
      component: Production,
      navIcon: (
        <Icon
          style={styles.footerIcon}
          icon={IconNames.PROJECTS}
          iconSize={25}
        />
      ),
      navButtonLabel: "Production",
      topIcon: IconNames.REFRESH,
      pageTitle: "Input Harian",
    },
    {
      component: History,
      navIcon: (
        <Icon
          style={styles.footerIcon}
          icon={IconNames.HISTORY}
          iconSize={25}
        />
      ),
      navButtonLabel: "Riwayat",
      topIcon: IconNames.REFRESH,
    },
    {
      component: Profile,
      navIcon: (
        <Icon style={styles.footerIcon} icon={IconNames.PERSON} iconSize={25} />
      ),
      navButtonLabel: "Profil",
      // topIcon: IconNames.REFRESH,
    },
  ] as IPage[];

  const defaultPageIndex = 0;
  const [selectedPage, setSelectedPage] = useState<number>(defaultPageIndex);

  const navButtons = pages.map((page, index) => {
    const pagePath = `${baseUrl}/${index}`;
    const navButtonPress = () => {
      setSelectedPage(index);
      hist.push(pagePath);
    };

    const isSelectedLink = selectedPage === index;

    return (
      <div
        key={`nav-link-${page.navButtonLabel}-${index}`}
        onClick={navButtonPress}
        style={{
          ...styles.link,
          backgroundColor: isSelectedLink ? "var(--theme-light-gray)" : "",
        }}
      >
        <div>{page.navIcon}</div>
        {page.navButtonLabel}
      </div>
    );
  });

  const topIconDisabledStates = pages.map(() => {
    const [topIconIsDisabled, setTopIconIsDisabled] = useState<boolean>(false);
    return { topIconIsDisabled, setTopIconIsDisabled };
  });

  const bodies = pages.map((page, index) => {
    const pagePath = `${baseUrl}/${index}`;
    // eslint-disable-next-line @typescript-eslint/no-empty-function
    const onTopIconClickRef = useRef<() => void>(() => {});
    const { topIconIsDisabled, setTopIconIsDisabled } =
      topIconDisabledStates[index];
    return (
      <Route
        key={`page-path-${page.navButtonLabel}-${index}`}
        exact
        path={pagePath}
      >
        <div
          style={page.component === Home ? styles.homeHeader : styles.header}
        >
          {page.pageTitle || page.navButtonLabel}
          <div onClick={() => onTopIconClickRef.current()}>
            {topIconIsDisabled ? (
              <Icon
                style={styles.headerIcon}
                icon={page.topIcon}
                iconSize={24}
              />
            ) : (
              <>
                {page.component === Home ? (
                  <div style={{ textAlign: "right" }}>
                    <div
                      style={{
                        fontSize: "0.8rem",
                        textAlign: "center",
                        background: "rgba(0, 0, 0, 50%)",
                        borderRadius: "10rem",
                        width: "80%",
                        left: "50%",
                        position: "relative",
                        lineHeight: 1.5,
                      }}
                    >
                      {uncheckedRecordNum! > 0 && uncheckedRecordNum}
                    </div>
                    <Icon
                      style={{ ...styles.headerIcon, marginTop: "-40%" }}
                      icon={page.topIcon}
                      color={"#ffffff"}
                      iconSize={24}
                    />
                  </div>
                ) : (
                  <Icon
                    style={styles.headerIcon}
                    icon={page.topIcon}
                    color={"black"}
                    iconSize={24}
                  />
                )}
              </>
            )}
          </div>
        </div>
        <div style={styles.body}>
          {page.component({
            baseUrl: pagePath,
            onTopIconClickRef,
            topIconIsDisabled,
            setTopIconIsDisabled,
            filteredCardDate,
            setUncheckedRecordNum,
            accessToken,
            role,
          })}
        </div>
      </Route>
    );
  });
  return (
    <div>
      <Switch>
        {bodies}
        <Route path={"/"}>
          <Redirect to={`${baseUrl}/${defaultPageIndex}`} />
        </Route>
      </Switch>
      <div style={styles.footer}>{navButtons}</div>
    </div>
  );
};
