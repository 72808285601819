import { Button } from "@blueprintjs/core";
import React, { FunctionComponent } from "react";
import { DailyRecordType } from "../../../../lib/sharedBackendModels";
import { DialogCard } from "../components/DialogCard";

const styles = {
  dialog: {
    textAlign: "center" as const,
    padding: "10% 5%",
    margin: "5%",
    justifyContent: "space-evenly",
  },
  title: { fontSize: "var(--theme-title-font-size)" },
  teamContainer: {
    display: "flex",
    flexDirection: "row" as const,
    justifyContent: "space-between",
  },
  button: {
    width: "100%",
    fontFamily: "Poppins",
    color: "var(--theme-purple)",
  },
};

export const CheckerDeleteTeamDialog: FunctionComponent<{
  isOpen: boolean;
  onClose: () => void;
  teamData: DailyRecordType | null;
  setIsCheckerDeleteTeamDialogOpen: React.Dispatch<
    React.SetStateAction<boolean>
  >;
  setIsCheckerSubmittedStatusDialogOpen: React.Dispatch<
    React.SetStateAction<boolean>
  >;
  isCheckerSubmittedStatusDialogOpen: boolean;
  deleteTeamProduction: () => void;
}> = ({
  isOpen,
  onClose,
  teamData,
  setIsCheckerDeleteTeamDialogOpen,
  deleteTeamProduction,
}) => {
  return (
    teamData && (
      <DialogCard
        isOpen={isOpen}
        onClose={onClose}
        title={<div>Delete Team {teamData.TeamRecord.Team.name}?</div>}
      >
        <div style={{ ...styles.teamContainer }}>
          <Button
            style={{ ...styles.button, margin: "1%" }}
            onClick={() => setIsCheckerDeleteTeamDialogOpen(false)}
            text={"Cancel"}
          />
          <Button
            style={{
              ...styles.button,
              margin: "1%",
              background: "#CD5C5C",
              color: "white",
            }}
            onClick={() => {
              deleteTeamProduction();
            }}
            text={"Delete"}
          />
        </div>
      </DialogCard>
    )
  );
};
