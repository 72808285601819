import { Card, Button } from "@blueprintjs/core";
import React, { FunctionComponent, useEffect, useState } from "react";
import { useHistory } from "react-router-dom";
import {
  DailyRecordStatus,
  DailyRecordType,
  FeedbackType,
  UserRoles,
} from "../../../../lib/sharedBackendModels";
import { dateFormatter, timeFormatter } from "../../../../utils/formatters";

const styles = {
  title: {
    fontWeight: "bolder" as const,
    color: "var(--theme-purple)",
    fontSize: "1.2rem",
  },
  subtitle: {
    fontSize: "0.8rem",
    textAlign: "left" as const,
    textTransform: "capitalize" as const,
    color: "var(--theme-medium-gray)",
    fontWeight: "bold" as const,
  },
  teamContainer: {
    display: "flex",
    flexDirection: "row" as const,
    justifyContent: "space-between",
    width: "100%",
  },
  button: {
    color: "var(--theme-purple)",
    background: "white",
  },
};

export const TeamDataCard: FunctionComponent<{
  allDailyRecordsData: DailyRecordType[];
  setIsConfirmTeamDialogOpen?: React.Dispatch<React.SetStateAction<boolean>>;
  setIsDeleteTeamDialogOpen?: React.Dispatch<React.SetStateAction<boolean>>;
  setIsRevisionReasonEntryDialogOpen?: React.Dispatch<
    React.SetStateAction<boolean>
  >;
  IsRevisionReasonEntryDialogOpen?: boolean;
  setCurrentDailyRecordData?: React.Dispatch<
    React.SetStateAction<DailyRecordType | null>
  >;
  currentDailyRecordData?: DailyRecordType | null;
  isHomePage?: boolean;
  revisionReasonEntry?: string | undefined;
  setRevisionReasonEntry?: React.Dispatch<
    React.SetStateAction<string | undefined>
  >;
  setCreateFeedbackRequest?: React.Dispatch<
    React.SetStateAction<FeedbackType | undefined>
  >;
  role: string;
}> = ({
  allDailyRecordsData,
  setIsConfirmTeamDialogOpen,
  setIsDeleteTeamDialogOpen,
  setCurrentDailyRecordData,
  currentDailyRecordData,
  isHomePage,
  setIsRevisionReasonEntryDialogOpen,
  revisionReasonEntry,
  setRevisionReasonEntry,
  setCreateFeedbackRequest,
  role,
}) => {
  const hist = useHistory();

  useEffect(() => {
    allDailyRecordsData;
  }, []);

  const [dailyRecordStatus, setDailyRecordStatus] =
    useState<DailyRecordStatus>();

  useEffect(() => {
    setCreateFeedbackRequest &&
      setCreateFeedbackRequest({
        userID: currentDailyRecordData?.userID || "",
        dailyRecordID: currentDailyRecordData?.id || "",
        comment: revisionReasonEntry || "nil",
        status: dailyRecordStatus!,
      });
  }, [
    currentDailyRecordData,
    setCurrentDailyRecordData,
    revisionReasonEntry,
    dailyRecordStatus,
    setDailyRecordStatus,
    setRevisionReasonEntry,
  ]);

  return (
    <>
      {allDailyRecordsData.map(
        (dailyRecord: DailyRecordType, index: number) => {
          const canEditDailyRecord =
            dailyRecord.status === DailyRecordStatus.Rejected &&
            dailyRecord.feedbackNum! < 4 &&
            role === UserRoles.Operator;
          const canReviewDailyRecord =
            (dailyRecord.status === DailyRecordStatus.Started ||
              dailyRecord.status === DailyRecordStatus.Pending) &&
            role === UserRoles.Checker &&
            dailyRecord.feedbackNum! < 4;

          const userOne = dailyRecord?.TeamRecord.Team.TeamMemberships[0].User;
          const userTwo = dailyRecord?.TeamRecord.Team.TeamMemberships[1].User;
          return (
            <Card
              elevation={2}
              key={`team-${dailyRecord.status}-data-${index}`}
              style={{ marginBottom: "2%" }}
            >
              <div style={styles.teamContainer}>
                <div style={{ ...styles.title, color: "black" }}>
                  Team {dailyRecord.TeamRecord?.Team.name}
                </div>
                <div style={{ color: "orange", margin: "auto 0% 0% auto" }}>
                  <div style={{ color: "orange", margin: "auto 0% 0% auto" }}>
                    {dailyRecord.status}
                  </div>
                  {canEditDailyRecord && isHomePage && (
                    <Button
                      style={{
                        ...(!canEditDailyRecord ? "" : styles.button),
                        fontFamily: "Poppins",
                        margin: "1%",
                        width: "100%",
                      }}
                      text="Edit"
                      onClick={() => {
                        hist.push({
                          pathname: "/dashboard/1",
                          state: { targetDailyRecordForRevision: dailyRecord },
                        });
                      }}
                    />
                  )}
                </div>
              </div>
              <div style={styles.subtitle}>
                {`${dateFormatter(dailyRecord.RecordShift?.Shift.startDate)}`}
                <div>
                  {`${timeFormatter(
                    dailyRecord.RecordShift?.Shift.startDate,
                  )} s/d ${timeFormatter(
                    dailyRecord.RecordShift?.Shift.endDate,
                  )}`}
                </div>
              </div>
              <hr style={{ width: "100%" }} />
              {dailyRecord.TeamRecord?.Team.TeamMemberships && (
                <div style={styles.teamContainer}>
                  <div>
                    <div style={styles.subtitle}>{userOne.name}</div>
                    <div style={styles.title}>
                      {userOne.RecordResults.map((r) => {
                        if (r.dRecordID === dailyRecord.id) {
                          return r.cratReached;
                        }
                      })}
                    </div>
                  </div>
                  <div>
                    <div style={styles.subtitle}>{userTwo.name}</div>
                    <div style={styles.title}>
                      {userTwo.RecordResults.map((r) => {
                        if (r.dRecordID === dailyRecord.id) {
                          return r.cratReached;
                        }
                      })}
                    </div>
                  </div>
                  <div>
                    <div style={styles.subtitle}>Total</div>
                    <div style={styles.title}>{dailyRecord.productionCrat}</div>
                  </div>
                </div>
              )}
              <hr style={{ width: "100%" }} />
              {!isHomePage && (
                <div>
                  <div style={styles.subtitle}>Revisi Info:</div>
                  {dailyRecord.Feedbacks.map((f) => {
                    if (f.dailyRecordID === dailyRecord.id) {
                      return (
                        <div
                          style={{
                            ...styles.subtitle,
                            fontWeight: "normal",
                            color: "black",
                          }}
                        >
                          {f.comment}
                        </div>
                      );
                    }
                  })}
                </div>
              )}
              {role === UserRoles.Checker && isHomePage && (
                <div style={{ ...styles.teamContainer }}>
                  <Button
                    style={{
                      ...(!canReviewDailyRecord ? "" : styles.button),
                      margin: "1%",
                      width: "100%",
                    }}
                    text="Ajukan Revisi"
                    disabled={!canReviewDailyRecord}
                    onClick={() => {
                      setRevisionReasonEntry && setRevisionReasonEntry("");
                      setIsRevisionReasonEntryDialogOpen &&
                        setIsRevisionReasonEntryDialogOpen(true);
                      setDailyRecordStatus(DailyRecordStatus.Rejected);
                      setCurrentDailyRecordData &&
                        setCurrentDailyRecordData(dailyRecord);
                    }}
                  />
                  <Button
                    style={{
                      ...(!canReviewDailyRecord ? "" : styles.button),
                      margin: "1%",
                      width: "100%",
                    }}
                    disabled={!canReviewDailyRecord}
                    onClick={() => {
                      setDailyRecordStatus(DailyRecordStatus.Confirmed);
                      setIsConfirmTeamDialogOpen &&
                        setIsConfirmTeamDialogOpen(true);
                      setCurrentDailyRecordData &&
                        setCurrentDailyRecordData(dailyRecord);
                      setRevisionReasonEntry && setRevisionReasonEntry("nil");
                    }}
                    text="Confirm"
                  />
                  <Button
                    style={{
                      ...(!canReviewDailyRecord ? "" : styles.button),
                      margin: "1%",
                      width: "100%",
                      color: "#CD5C5C",
                    }}
                    disabled={!canReviewDailyRecord}
                    onClick={() => {
                      // setDailyRecordStatus(DailyRecordStatus.Confirmed);
                      setIsDeleteTeamDialogOpen &&
                        setIsDeleteTeamDialogOpen(true);
                      setCurrentDailyRecordData &&
                        setCurrentDailyRecordData(dailyRecord);
                      setRevisionReasonEntry && setRevisionReasonEntry("nil");
                    }}
                    text="Delete"
                  />
                </div>
              )}
            </Card>
          );
        },
      )}
    </>
  );
};
